import { Body, Header } from "@/components/Typography/Typography";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";

type Props = {
  is404Page?: boolean;
};

export function GenericError({ is404Page = false }: Props) {
  const { t } = useTranslation("common", { keyPrefix: "error" });
  const navigate = useNavigate();

  const error = useRouteError();
  const is404 =
    is404Page || (isRouteErrorResponse(error) ? error.status === 404 : false);

  return (
    <div className="min-h-dvh flex flex-col items-center justify-center gap-6 px-4">
      <BrokenRecordIcon />
      <div className="flex max-w-xl flex-col items-center gap-4">
        <Header size="h1" className="text-foreground-one">
          {is404 ? t("404Error") : t("genericError")}
        </Header>
        <Body className="text-foreground-two text-center">
          {is404 ? t("404Description") : t("genericDescription")}
        </Body>
      </div>
      <Button
        onClick={() => {
          navigate("/dashboard");
        }}>
        {t("backToDashboard")}
      </Button>
    </div>
  );
}

function BrokenRecordIcon() {
  return (
    <svg
      width="120"
      height="121"
      viewBox="0 0 500 501"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M407.379 55.8777L399 61.5L389.502 65.0065C389.502 65.0065 390.423 71.3838 390.643 75.5C390.859 79.5291 390.533 81.7986 390.643 85.8319C390.754 89.9029 392.3 92.2759 392.5 96.5C392.688 100.472 392.374 102.725 391.784 106.657C391.357 109.5 330.734 161.812 330.734 161.812C330.734 161.812 334.562 150.174 336.5 142.5C338.391 135.011 338.046 130.324 340.5 123C343.092 115.264 345.583 111.293 348 103.5C350.251 96.2429 353 86 352.605 84.5965C352.605 82.5 340.005 64 338.721 59.302C337.438 54.6041 341.955 17.6513 341.955 17.6513C320.5 8 282.522 2.5 250 2.5C198.432 2.5 151.288 17.251 111.5 44C78.3521 66.2851 47.2987 93.3093 28 128.5C8.43973 164.168 2 205.896 2 249.5C2 279.08 2.60108 308.189 12 334.5C18.4479 352.55 31.2083 367.895 41 384C50.4039 399.468 59.2204 415.792 72 428.5C96.368 452.732 128.84 469.428 161.5 482C189.395 492.738 219.304 499 251 499C286.2 499 319.111 490.414 349.5 478C375.39 467.424 398.517 455.791 419 437C429.911 426.99 438.872 412.306 448 401C461.572 384.19 474.097 367.234 482.5 346.5C487.961 333.025 489.559 316.736 492.5 302.5C495.877 286.156 499.5 269.223 499.5 251.5C499.5 234.186 499.741 215.43 497 199C493.786 179.735 487.51 161.759 479.5 144.5C463.351 109.704 436.813 79.7609 407.379 55.8762V55.8777ZM249.905 265.464C241.536 265.464 234.69 258.616 234.69 250.249C234.69 241.881 241.537 234.845 249.905 234.845C258.272 234.845 265.309 241.692 265.309 250.249C265.309 258.806 258.461 265.464 249.905 265.464Z"
        fill="#38383D"
      />
      <path
        d="M249.904 377.864V395.933C290.223 395.933 326.739 379.576 352.985 353.141C379.42 326.894 395.776 290.378 395.776 250.249H377.709C377.709 285.435 363.446 317.384 340.243 340.398C317.23 363.6 285.277 377.866 249.903 377.866L249.904 377.864Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 372.538C283.759 372.538 314.378 358.845 336.44 336.592C358.501 314.531 372.194 283.911 372.194 250.248H265.31C265.31 258.616 258.463 265.462 249.906 265.462V372.537L249.905 372.538Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 401.256V419.324C296.689 419.324 338.912 400.496 369.531 369.877C400.151 339.255 419.169 296.844 419.169 250.249H401.102C401.102 291.9 384.176 329.748 356.788 357.134C329.592 384.33 291.745 401.256 249.905 401.256Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 448.232V466.3C309.624 466.3 363.636 442.145 402.813 402.968C441.993 363.979 466.146 309.776 466.146 250.248H448.079C448.079 304.832 425.827 354.47 390.072 390.225C354.127 426.172 304.678 448.232 249.905 448.232Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 424.839V442.907C303.156 442.907 351.273 421.226 386.269 386.421C421.072 351.618 442.564 303.31 442.564 250.248H424.686C424.686 298.364 405.097 342.107 373.526 373.678C341.764 405.25 298.213 424.838 249.906 424.838L249.905 424.839Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 28.491V16.8899C121.149 16.8899 16.7349 121.301 16.7349 250.248H28.3374C28.3374 189.007 53.2519 133.472 93.1918 93.5348C133.321 53.4055 188.856 28.4896 249.906 28.4896L249.905 28.491Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 127.767C216.241 127.767 185.621 141.461 163.559 163.714C141.307 185.774 127.614 216.395 127.614 250.249H234.688C234.688 241.691 241.536 234.845 249.903 234.845V127.769L249.905 127.767Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 122.443V104.376C209.775 104.376 173.26 120.731 146.824 147.168C120.577 173.413 104.222 209.929 104.222 250.248H122.289C122.289 214.873 136.552 182.922 159.566 159.908C182.769 136.706 214.721 122.442 249.906 122.442L249.905 122.443Z"
        fill="#2A2A2D"
      />
      <path
        d="M471.472 250.248C471.472 311.299 446.748 366.832 406.617 406.771C366.488 446.899 311.145 471.816 249.905 471.816V483.417C378.851 483.417 483.264 379.006 483.264 250.249H471.472V250.248Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 75.4662V57.3992C196.653 57.3992 148.535 79.08 113.731 113.885C78.7357 148.69 57.2456 196.997 57.2456 250.249H75.3127C75.3127 201.943 94.9012 158.2 126.472 126.629C158.043 95.0576 201.785 75.4677 249.903 75.4677L249.905 75.4662Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 52.0746V34.0061C190.185 34.0061 136.172 58.1605 97.1856 97.3391C58.0056 136.328 33.854 190.53 33.854 250.249H51.7304C51.7304 195.475 73.9819 145.837 109.928 110.08C145.684 74.1354 195.322 52.0746 249.906 52.0746H249.905Z"
        fill="#2A2A2D"
      />
      <path
        d="M249.905 99.05V80.9829C203.309 80.9829 160.896 99.8114 130.277 130.432C99.6565 161.052 80.6387 203.463 80.6387 250.248H98.7072C98.7072 208.407 115.633 170.56 143.019 143.173C170.406 115.977 208.254 99.05 249.905 99.05Z"
        fill="#2A2A2D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M249.905 156.297C301.826 156.297 343.855 198.326 343.855 250.247C343.855 302.169 301.826 344.009 249.905 344.009C197.984 344.009 156.144 301.979 156.144 250.247C156.144 198.516 198.174 156.297 249.905 156.297ZM249.905 234.843C258.463 234.843 265.309 241.691 265.309 250.247C265.309 258.804 258.462 265.462 249.905 265.462C241.348 265.462 234.69 258.615 234.69 250.247C234.69 241.88 241.537 234.843 249.905 234.843Z"
        fill="#38383D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M249.905 168.659C294.978 168.659 331.305 205.175 331.305 250.249C331.305 295.323 294.978 331.649 249.905 331.649C204.831 331.649 168.505 295.133 168.505 250.249C168.505 205.364 205.022 168.659 249.905 168.659ZM249.905 234.843C258.463 234.843 265.309 241.691 265.309 250.247C265.309 258.804 258.461 265.462 249.905 265.462C241.348 265.462 234.69 258.615 234.69 250.247C234.69 241.88 241.537 234.843 249.905 234.843Z"
        fill="#22C55E"
      />
      <path
        d="M262.267 302.17H237.543C235.64 302.17 233.93 303.881 233.93 305.782C233.93 307.874 235.64 309.585 237.543 309.585H262.267C264.359 309.585 266.07 307.874 266.07 305.782C266.07 303.881 264.359 302.17 262.267 302.17Z"
        fill="#16A34A"
      />
      <path
        d="M282.997 279.917H216.812C214.912 279.917 213.198 281.438 213.198 283.529C213.198 285.62 214.912 287.335 216.812 287.335H282.997C285.089 287.335 286.802 285.623 286.802 283.529C286.802 281.436 285.089 279.917 282.997 279.917Z"
        fill="#16A34A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M368.962 14C368.962 14 368.739 19.2421 368 22.5C367.222 25.933 365 31 365 31C365 31 365.059 36.4136 364.5 39.8165C363.934 43.2577 362.114 48.422 362.114 48.422C362.114 48.422 368.906 47.1155 373 45.5C377.337 43.7887 379.086 41.3188 383.5 39.8165C387.809 38.3497 390.639 39.2194 395 37.9131C399.203 36.6541 405.288 33.3993 405.288 33.3993C405.288 33.3993 399.148 31.0042 395.5 28.9131C392.211 27.0276 390.842 25.2036 387.5 23.4131C383.632 21.3408 380.878 21.4658 377 19.4131C373.655 17.6427 368.962 14 368.962 14Z"
        fill="#38383D"
      />
    </svg>
  );
}

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";
import { queryClient } from "../client";
import { ToursWithArtistDto } from "../base-api";

type TourQueryOptions = {
  filter?: {
    artistId?: string;
  };
};

export const toursQueryOptions = (options?: TourQueryOptions) =>
  queryOptions({
    queryKey: ["tours", "list", options?.filter?.artistId],
    queryFn: async () => {
      if (options?.filter?.artistId) {
        const response = queryClient.getQueryData<ToursWithArtistDto>([
          "tours",
          "list",
          null,
        ]);

        if (!response) {
          const response = await API.tours.toursControllerFindAll({
            artistId: options.filter.artistId,
          });
          return response.data;
        }

        if (response.tours.length > 0) {
          return {
            tours: response.tours.filter(
              (tour) => tour.artistId === options.filter?.artistId
            ),
          };
        }
        return { tours: [] } as ToursWithArtistDto;
      }
      const response = await API.tours.toursControllerFindAll();
      return response.data;
    },
  });

export const useTours = (options?: TourQueryOptions) => {
  return useQuery(toursQueryOptions(options));
};

export const useToursSuspense = (options?: TourQueryOptions) => {
  return useSuspenseQuery(toursQueryOptions(options));
};

import { queryOptions, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { API } from "../api";

export const artistQueryOptions = (artistId: string) =>
  queryOptions({
    queryKey: ["artists", artistId],
    queryFn: async () => {
      const response = await API.artists.artistsControllerFindOne(artistId);

      return response.data;
    },
  });

export const useArtist = (artistId: string) => {
  return useQuery(artistQueryOptions(artistId));
};

export const useArtistSuspense = (artistId: string) => {
  return useSuspenseQuery(artistQueryOptions(artistId));
};

import { createContext, ReactNode, useContext, useState } from "react";

interface DayState {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

const TourDayContext = createContext<DayState | undefined>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useTourDayState = () => {
  const context = useContext(TourDayContext);
  if (!context) {
    throw new Error("useTourDayState must be used within a TourDayProvider");
  }
  return context;
};

export const TourDayProvider = ({ children }: { children: ReactNode }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <TourDayContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </TourDayContext.Provider>
  );
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const eventTypesQueryOptions = queryOptions({
  queryKey: ["eventTypes"],
  queryFn: async () => {
    const response = await API.events.eventsControllerFindAll();
    return response.data;
  },
});

export const useEventTypes = () => {
  return useQuery(eventTypesQueryOptions);
};

export const useEventTypesSuspense = () => {
  return useSuspenseQuery(eventTypesQueryOptions);
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const personalDetailsQueryOptions = queryOptions({
  queryKey: ["personalDetails"],
  queryFn: async () => {
    const response =
      await API.users.userPersonalDetailsControllerFindCurrentUser();
    return response.data;
  },
});

export const usePersonalDetails = () => {
  return useQuery(personalDetailsQueryOptions);
};

export const usePersonalDetailsSuspense = () => {
  return useSuspenseQuery(personalDetailsQueryOptions);
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const riderQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ["riders", id],
    queryFn: async () => {
      const response = await API.riders.ridersControllerFindOne(id);
      return response.data;
    },
  });

export const useRider = (id: string) => {
  return useQuery(riderQueryOptions(id));
};

export const useRiderSuspense = (id: string) => {
  return useSuspenseQuery(riderQueryOptions(id));
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const emergencyContactsQueryOptions = queryOptions({
  queryKey: ["emergencyContacts"],
  queryFn: async () => {
    const response =
      await API.users.userEmergencyContactsControllerFindManyCurrentUser();
    return response.data;
  },
});

export const useEmergencyContacts = () => {
  return useQuery(emergencyContactsQueryOptions);
};

export const useEmergencyContactsSuspense = () => {
  return useSuspenseQuery(emergencyContactsQueryOptions);
};

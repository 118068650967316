import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

// DISPLAY

const displayVariants = cva("font-medium leading-tight tracking-tights", {
  variants: {
    size: {
      xl: "text-4xl",
      lg: "text-2xl",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

export interface DisplayProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof displayVariants> {
  asChild?: boolean;
  contentRef?: React.Ref<HTMLDivElement>;
}

export function Display({ className, ...props }: DisplayProps) {
  const Comp = props.asChild ? Slot : "div";
  return (
    <Comp
      className={cn(displayVariants({ size: props.size, className }))}
      ref={props.contentRef}
      {...props}>
      {props.children}
    </Comp>
  );
}

// HEADER

const headerVariants = cva("font-medium leading-tight tracking-tight", {
  variants: {
    size: {
      h1: "text-[22px]",
      h2: "text-lg",
      h3: "text-base",
      h4: "text-sm",
    },
  },
  defaultVariants: {
    size: "h3",
  },
});

export interface HeaderProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headerVariants> {
  asChild?: boolean;
  contentRef?: React.Ref<HTMLHeadingElement>;
}

export function Header({ size = "h3", className, ...props }: HeaderProps) {
  const Comp = props.asChild ? Slot : size;

  if (!Comp) throw new Error("Header must have a size prop");

  return (
    <Comp
      className={cn(headerVariants({ size: size, className }))}
      ref={props.contentRef}
      {...props}>
      {props.children}
    </Comp>
  );
}

// Body

const bodyVariants = cva("font-normal, leading-normal, tracking-[0.0175rem]", {
  variants: {
    size: {
      lg: "text-base",
      md: "text-sm",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type BodyProps = React.HTMLAttributes<HTMLParagraphElement> &
  VariantProps<typeof bodyVariants> & {
    asChild?: boolean;
    contentRef?: React.Ref<HTMLParagraphElement>;
  };

export function Body({ className, ...props }: BodyProps) {
  const Comp = props.asChild ? Slot : "p";

  return (
    <Comp
      className={cn(bodyVariants({ size: props.size, className }))}
      ref={props.contentRef}
      {...props}>
      {props.children}
    </Comp>
  );
}

// Label

const labelVariants = cva(
  "block font-normal, leading-none, tracking-[0.0175rem]",
  {
    variants: {
      size: {
        md: "text-sm",
        sm: "text-xs",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

export interface LabelProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof labelVariants> {
  asChild?: boolean;
  contentRef?: React.Ref<HTMLSpanElement>;
}

export function LabelText({ className, ...props }: LabelProps) {
  const Comp = props.asChild ? Slot : "span";
  return (
    <Comp
      className={cn(labelVariants({ size: props.size, className }))}
      ref={props.contentRef}
      {...props}>
      {props.children}
    </Comp>
  );
}

import { useAuthStore } from "@/stores/auth/auth-store";
import { BaseApi } from "./base-api";
import { router } from "@/modules/Router/router";
import { AxiosError } from "axios";

const baseURL =
  import.meta.env.VITE_API_URL || "https://api.articulate.localhost";

const getAuthorizationHeader = () => {
  const token = useAuthStore.getState().authToken;
  return token ? `Bearer ${token}` : "";
};

export const API = new BaseApi({
  baseURL,
  headers: {
    Authorization: getAuthorizationHeader(),
  },
});

API.instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      useAuthStore.getState().setTokens(null, null);
      removeAuthHeader();
      router.navigate("/login");
    }
    return Promise.reject(error);
  }
);

export const updateAuthorizationHeader = (token: string) => {
  API.instance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeAuthHeader = () => {
  API.instance.defaults.headers.Authorization = "";
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const currentUserQueryOptions = queryOptions({
  queryKey: ["currentUser"],
  queryFn: async () => {
    const response = await API.users.usersControllerGetCurrentUser();
    return response.data;
  },
});

export const useCurrentUser = () => {
  return useQuery(currentUserQueryOptions);
};

export const useCurrentUserSuspense = () => {
  return useSuspenseQuery(currentUserQueryOptions);
};

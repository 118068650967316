import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const chatRoomsQueryOptions = queryOptions({
  queryKey: ["chatRooms"],
  queryFn: async () => {
    const response = await API.chatRooms.chatRoomsControllerFindAll();
    return response.data;
  },
});

export const useChatRooms = () => {
  return useQuery(chatRoomsQueryOptions);
};

export const useChatRoomsSuspense = () => {
  return useSuspenseQuery(chatRoomsQueryOptions);
};

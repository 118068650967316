import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const travellingDetailsQueryOptions = queryOptions({
  queryKey: ["travellingDetails"],
  queryFn: async () => {
    const response =
      await API.users.userTravellingDetailsControllerFindCurrentUser();
    return response.data;
  },
});

export const useTravellingDetails = () => {
  return useQuery(travellingDetailsQueryOptions);
};

export const useTravellingDetailsSuspense = () => {
  return useSuspenseQuery(travellingDetailsQueryOptions);
};

import { IconContainer, IconContainerProps } from "./IconContainer";
import * as Icons from "./icons";

export type IconName = keyof typeof Icons;
export type IconProps = {
  name: IconName;
} & Omit<IconContainerProps, "icon">;

export function Icon(props: IconProps) {
  const ParsedIcon = Icons[props.name];
  return <IconContainer icon={ParsedIcon} {...props} />;
}

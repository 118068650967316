import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const riderPersonnelQueryOptions = (riderId: string) =>
  queryOptions({
    queryKey: ["riderPersonnel", riderId],
    queryFn: async () => {
      const response =
        await API.riders.ridersControllerFindAllPersonnel(riderId);
      return response.data;
    },
  });

export const useRiderPersonnel = (riderId: string) => {
  return useQuery(riderPersonnelQueryOptions(riderId));
};

export const useRiderPersonnelSuspense = (riderId: string) => {
  return useSuspenseQuery(riderPersonnelQueryOptions(riderId));
};

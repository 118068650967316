import * as Sentry from "@sentry/react";
import { Outlet, useLocation } from "react-router-dom";

export function DashboardErrorBoundary({
  fallback,
}: {
  fallback?: React.ReactElement;
}) {
  const location = useLocation();
  return (
    <Sentry.ErrorBoundary
      key={location.pathname}
      fallback={fallback || <div>Error</div>}
      showDialog>
      <Outlet />
    </Sentry.ErrorBoundary>
  );
}

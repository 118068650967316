import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const riderExternalPersonnelQueryOptions = (riderId: string) =>
  queryOptions({
    queryKey: ["riderExternalPersonnel", riderId],
    queryFn: async () => {
      const response =
        await API.riders.ridersControllerFindAllExternalPersonnel(riderId);
      return response.data;
    },
  });

export const useRiderExternalPersonnel = (riderId: string) => {
  return useQuery(riderExternalPersonnelQueryOptions(riderId));
};

export const useRiderExternalPersonnelSuspense = (riderId: string) => {
  return useSuspenseQuery(riderExternalPersonnelQueryOptions(riderId));
};

const THEME_KEY = "theme";

export const saveTheme = (theme: "light" | "dark") => {
  localStorage.setItem(THEME_KEY, theme);

  if (theme === "dark") {
    document.documentElement.classList.add("dark");
    return;
  }
  document.documentElement.classList.remove("dark");
};

export const getTheme = () => {
  const theme = localStorage.getItem(THEME_KEY);

  return theme;
};

export const saveInitialTheme = () => {
  const theme = getTheme() as "light" | "dark" | undefined | null;

  if (theme) {
    saveTheme(theme);
    return;
  }

  const preferDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  saveTheme(preferDark ? "dark" : "light");
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import engDashboard from "./en/dashboard.json";
import engAuth from "./en/auth.json";
import engCommon from "./en/common.json";
import engAdmin from "./en/admin.json";
import engForms from "./en/forms.json";

const ns = ["common", "dashboard", "auth", "forms"] as const;

export const resources = {
  en: {
    common: engCommon,
    dashboard: engDashboard,
    auth: engAuth,
    admin: engAdmin,
    forms: engForms,
  },
} as const;

export const defaultNS = "common" as const;

i18n.use(initReactI18next).init({
  resources,
  ns,
  defaultNS,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { SVGProps } from "react";
import * as Icons from "./icons";
import { cn } from "@/lib/utils";

export type IconContainerProps = {
  icon: (typeof Icons)["AddIcon"];
  size?: "base" | "small" | "lg" | "custom";
} & SVGProps<SVGSVGElement>;

export function IconContainer({
  icon: Icon,
  size = "base",
  className,
  ...props
}: IconContainerProps) {
  return (
    <Icon
      className={cn(
        {
          "h-4 w-4": size === "small",
          "h-6 w-6": size === "base",
          "h-8 w-8": size === "lg",
        },
        className
      )}
      {...props}
    />
  );
}

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const workspaceArtistsQueryOptions = queryOptions({
  queryKey: ["artists", "list"],
  queryFn: async () => {
    const response = await API.artists.artistsControllerFindAll();
    return response.data;
  },
});

export const useArtists = () => {
  return useQuery(workspaceArtistsQueryOptions);
};

export const useArtstsSuspense = () => {
  return useSuspenseQuery(workspaceArtistsQueryOptions);
};

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";
import { EventsDto } from "../base-api";
import { toZonedTime } from "date-fns-tz";
import { formatDate, getDaysForInterval } from "@/lib/dates";

export const tourEventsQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourEvents", tourId],
    queryFn: async () => {
      const response = await API.events.eventsControllerFindAllByTour(tourId);
      return response.data;
    },
    select(data) {
      const allEventsValuesNested = Object.values(data.events).flat();

      let returnObject: EventsDto = { events: {} };
      const usedEvents = new Set<string>();

      allEventsValuesNested.forEach((event) => {
        if (usedEvents.has(event.id)) {
          return;
        }
        usedEvents.add(event.id);
        const date = toZonedTime(event.startDate!, event.timezoneId!);
        const endDate = toZonedTime(event.endDate!, event.timezoneId!);

        const days = getDaysForInterval(
          date.toISOString(),
          endDate.toISOString()
        );

        const daysFormatted = days.map((day) => formatDate(day));

        daysFormatted.forEach((day) => {
          returnObject = {
            events: {
              ...returnObject.events,
              [day]: returnObject.events[day]
                ? [...returnObject.events[day], event]
                : [event],
            },
          };
        });
      });

      return returnObject;
    },
    enabled: !!tourId,
  });

export const useTourEvents = (tourId: string) => {
  return useQuery(tourEventsQueryOptions(tourId));
};

export const useTourEventsSuspense = (tourId: string) => {
  return useSuspenseQuery(tourEventsQueryOptions(tourId));
};

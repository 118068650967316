import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const reportQuestionsColumnsQueryOptions = queryOptions({
  queryKey: ["reportQuestionsColumns"],
  queryFn: async () => {
    const response =
      await API.tableColumns.tableColumnsControllerFindSupplementalQuestions();
    return response.data;
  },
});

export const useReportQuestionsColumns = () => {
  return useQuery(reportQuestionsColumnsQueryOptions);
};

export const useReportQuestionsColumnsSuspense = () => {
  return useSuspenseQuery(reportQuestionsColumnsQueryOptions);
};

import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStore = {
  authToken: string | null;
  refreshToken: string | null;
  removeAuthToken: () => void;
  removeRefreshToken: () => void;
  setAuthToken: (authToken: string) => void;
  setRefreshToken: (authToken: string) => void;
  setTokens: (authToken: string | null, refreshToken: string | null) => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      authToken: null,
      refreshToken: null,
      removeAuthToken: () => set({ authToken: null }),
      removeRefreshToken: () => set({ refreshToken: null }),
      setAuthToken: (authToken) => set({ authToken }),
      setRefreshToken: (refreshToken) => set({ refreshToken }),
      setTokens: (authToken, refreshToken) => set({ authToken, refreshToken }),
    }),
    {
      name: "auth",
    }
  )
);
